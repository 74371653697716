<div class="row">
    <div class="mshow" style="width: 100%;margin-top: -10px;">&nbsp;</div>
    <div class="  col-lg-3 col-md-12 col-sm-12" (click)="load_approval_Entry()">
        <div class="vcard card-stats" style="padding: 0px !important;border-radius: 6px!important;">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon vshade">
                    <i class="bx bx-user"></i>
                </div>
                <p class="card-category">Today Visiter</p>
                <h3 class="card-title">0
                    <small>Nos</small>
                </h3>
            </div>

        </div>
    </div>
    <div class="  col-lg-3 col-md-12 col-sm-12">
        <div class="vcard card-stats" style="padding: 0px !important;border-radius: 6px!important;">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon gshade">
                    <i class="bx bx-user-plus"></i>
                </div>
                <p class="card-category">Today Test Visiter</p>
                <h3 class="card-title">0
                    <small>Nos</small>
                </h3>
            </div>
        </div>
    </div>
    <div class="  col-lg-3 col-md-12 col-sm-12">
        <div class="vcard card-stats" style="padding: 0px !important;border-radius: 6px!important;">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon oshade">
                    <i class="bx bxs-wallet-alt"></i>
                </div>
                <p class="card-category">Today High Score</p>
                <h3 class="card-title">0
                    <small>Nos</small>
                </h3>
            </div>
        </div>
    </div>
    <div class=" col-lg-3 col-md-12 col-sm-12">
        <div class="vcard card-stats" style="padding: 0px !important;border-radius: 6px!important;">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon bshade">
                    <i class="bx bxs-wallet-alt"></i>
                </div>
                <p class="card-category">Today Lowest Score</p>
                <h3 class="card-title">0
                    <small>Nos</small>
                </h3>
            </div>

        </div>
    </div>


</div>