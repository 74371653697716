import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.scss']
})
export class SalesDashboardComponent implements OnInit {

 
  constructor(public appservice:AppService,private router: Router,) {
    
  }

  load_approval_Entry() {
    
    this.router.navigate(['visitor/add-schedule-visitor']);
  }

  ngOnInit(): void {
  }

}
