import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public access_tocken = "";
  public Server_URL = "http://localhost:55245/";
  public Quotation_Format="Format1";
  public System_mac_address="Not found"
  public Area_Map: boolean = false;
  public NT_Bill_Format = "NT_Format1"
  public Bill_Format = "Format1"
  public Bill_Format1 = "Format6";
  public Vadi_Format = "Format1";
  public check_Unicod: boolean = false;


  public TCS_Enable: boolean = true;
  public Balance_SMS = "false";


  public key_value="";

  public Intrest_Format="Flat"

  public NT_Printer: string = "false";
  public NT_Address: String = "http://localhost:55245/";

  public DB_Vadi_display: boolean = false;
  public Print_Button: boolean = false;

  public Ledger_Update:boolean=true;
  public Cust_Amt: boolean = false;
  public Lfrom = "34";
  public Top = 50;

  public Logo_location = "Top_qubha";
  public Logo_Name = "Qubha";
  public Logo_location_Loin = "Chef";
  public Logo_Sub_Name = "Soft";
  public Phone_No = "9597436220";

  public Qmenu: boolean = true;
  public STamilName: boolean = false;
  public Type_Based_Bill_No: boolean = false;
  public Item_Rate_Update: boolean = true;
  public Check_Stock: boolean = false;
  
  public Image_disp: boolean = false;
  public Ledger_Type = "1";

  public Group_Enable: boolean = false;
  public Print_Bill: boolean = false;
  
  public Save_Print: boolean = true;
  public Save_Print_Mobile: boolean = false;
  public Save_New: boolean = false;

  public  Stockbase_Sales: boolean = false;

  public  mobile_menu: boolean = false;

  
  public from_customer_page:boolean=false;
  public W_height = 0;
  public W_width = 0;

  public SM_Row = [];
  public Sales_Disp_Text2_Visblle: boolean = false;
  public Sales_Disp_Text3_Visblle: boolean = false;
  
  public Loan_Issue_Page="/collection/emi-loan-issue";
  public Add_Receipt_Page="/transaction/add-receipt-entry-two";
  public U_Rights=""
  public Item_Group_ = "1";
  public isadd_Page:boolean=false;
  lenth: number = 0;
  public GST_Code = "33";
  constructor(@Inject(DOCUMENT) private document: Document, private toastr: ToastrService, public datePipe: DatePipe, public http: HttpClient, public router: Router, private _location: Location) {


    if (this.getData()) {

      this.data = JSON.parse(localStorage.getItem('User_Data'));

      try {
        this.Customer_Area = localStorage.getItem('Area');
      } catch { }
      this.Company = "_" + this.data.UM_Company;
      this.GST_Code=this.data.CM_State_Code;
    }


    this.W_height = window.innerHeight;

    this.W_width = window.innerWidth;

    var Today_Date = this.datePipe.transform(new Date(), "dd-MMM-yyyy");

    this.T_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.T_Date_Time = this.datePipe.transform(new Date(), "yyyy-MM-ddTHH:mm");


    this.exp_Date = this.datePipe.transform(new Date(), "MM/yy");
    this.Pay_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.Sal_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");



    this.Y_Date = this.datePipe.transform(new Date().getDate() - 1, "dd-MMM-yyyy");
    this.Today_Date = Today_Date;


  
    this.S_From = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.S_To = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.From_Date = { formatted: Today_Date };
    this.To_Date = { formatted: Today_Date };
    this.Date = { formatted: Today_Date };
    this.Date1 = { formatted: Today_Date };


    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.load_themes('');



  }

  public lable_ = "";
  public item = "";
  public Filter_Type = "contain";

  public openModal: boolean = false;
  Open_Modal(Title, page) {
    this.openModal = true;
    $('#small_Module').modal('show');
  }

  Close_Modal() {
    this.openModal = false;
    $('#small_Module').modal('hide');
  }

  public Header_Disp = "";

  public add_item_name = "/add-items1";
  btndisable: boolean = false;
  isload: boolean = false;
  public Company = "";
  public CREATED_BY = "";
  public Ref_ID = "0";
  public isadd = "0";
  public Edit_Row: any = {};
  public Edit_Row1: any = {};

  public Selected_Row: any = {};
  public Default_ref_ID: string = "Role";
  public URL_Location = "";

  public disp_category:boolean=false;
  public img_visible: boolean = false;

  
  public Date;
  public Date1;
  public From_Date;
  public To_Date;

  public User_Type = "Admin";
  public Document_Type = "Front_Banner";
  public Phone_Pattern = "^((\\+91-?)|0)?[0-9]{10}$";
  public Receipt_Print="false";

  public Side_Menu: boolean = false;
  public Today_Date = "";
  public S_From = "";
  public S_To = "";
  public FS_Date = "2021-04-01";

  public S_Bill_Type = "All";
  public S_Thari_Status = "All";
  public Calibration_Standard ="";
  public Category = "All";

  public S_Pay_Mode = "0";
  public S_Bill_Mode = "All";
  public S_Area = "All"
  public S_Sales_Order_by = "Bill_No";
  public S_Stock = "All"
  public S_Sales_Person = "All"


  public Y_Date = "";
  public data: any = {};
  public Order_Status = "Pending";
  public Search_User = "All";
  public Approval_Status = "Pending";


  public Customer_Route="";
  public Customer_Area = "All";
  public item_name_disp = "English";

  public T_Date = "";
  public T_Date_Time = "";

  public Default_Payment_ID: string = "Cash";
  public Default_Bank_ID: string = "0";
  public Pay_Date="";
  public Sal_Date="";
  public exp_Date="";
  public Open_Customer_Total = 0;
  public Open_Customer_Length = 0;
  public DB_Expense_Total=0;
  public Emi_Loan_Issue_Total=0;
  public DB_Bank_Row=0;
  public DB_Bank_Total=0;
  Cash_ID = "CASH";
  Cheque_ID = "Cheque";
  Card_ID = "Card";
  VEH = "Maintanance";
  Default_Veh_ID="";
  public check_Unicode: boolean = false;
  public Stock_Check: boolean = true;

  public Tax_Type = "exclusive";
  public GST_Type = "local";
  public Temp_Order_Row = [];

  add_one: number = 1;
  remove_one: number = -1;
  public cart_item = [

  ];

  Clear_Cart() {

    this.Selected_Customer = {};
    this.cart_item = [];
    try {
      this.Item_Master_Rows.forEach(x => x.Order_Qty = "0");
      // this.Item_Master_Rows.forEach(x => x.Rate = "0");
      // this.Item_Master_Rows.forEach(x => x.Rate = "0");
    } catch { }

  }

  public Item_Group="";
  public User_Role_Menu = [
    { Menu: 'User Master', Link: '/user-details' },
    { Menu: 'Role Master', Link: '/role-master' },
    { Menu: 'Role Rights', Link: '/role-permissions' }

  ];

  public Stock_Report = [

    { Menu: 'Current Stock', Link: '/report/current-stock', class: '' },
    { Menu: 'Category Stock', Link: '/report/Categorywise-stock', class: '' },
    { Menu: 'Itemwise Stock', Link: '/report/itemwise-stock', class: '' },
    { Menu: 'Stock Report', Link: '/report/stock-report', class: '' }
  ];
  
  public Menu_Master_GF = ['Module', 'Type', 'Display_Name', 'Route_Link', 'Order_No', 'Icon', 'Remark'];
  public back() {
    this._location.back();
  }


  public datefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-dd");
  }

  public datetimefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-ddTHH:mm");
  }
  

  public date_display(data) {
    return this.datePipe.transform(data, "dd-MMM-yyyy");
  }

  public Rights_Name = "";
  public Rights_ID = "";
  public Emp_ID = "";
  public  To_Amt="0";
  public  Ledger_Amt="0";
get_CR_DB_Amount(data)
{
  this.get("Api/Transaction/get_CR_DB_Amount?Ledger_ID=" +data).subscribe((res: any) => {
   this.Ledger_Amt= res;
  });
 this.Default_Bank_ID=data;
}

  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }

  F_Row = [];
  get_pos_field(Table, pos) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.Visible == "True");
    this.F_Row = this.F_Row.filter(e => String(e.Posision).toLowerCase() == String(pos).toLowerCase());
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  Reference_Rows = [];
  get_Reference() {


    this.getc("Api/Master/get_reference").subscribe((res: any) => {
      this.Reference_Rows = JSON.parse(res).record;
    });
  }

  Product_Rows = [];
  public isEdit: boolean = false;
  public load_from_make_invoice: boolean = false;



  public User_Master_Rows = [];
  get_User_Master() {
    this.getc("Api/Common/get_User_Master").subscribe((res: any) => {
      this.User_Master_Rows = JSON.parse(res).record;
    });
  }


  public Selected_Company: any = {};
  public Selected_Customer: any = {};
  public Current_User: any = {};

  public Order_No = "0";
  public Menus_Rows: any = {};
  public Menu_Rows = [];
  public Label_Menu_Rows = [];
  get_Role_Rights(Role) {
    this.get("Api/Setting/get_Menu_for_user?Rights=" + Role).subscribe((res: any) => {
      this.Menu_Rows = JSON.parse(res).record;
      this.Side_Menu = true;
      try {
        this.Label_Menu_Rows = this.Menu_Rows.filter(e => e.Type == "Label");
        this.Label_Menu_Rows = this.Label_Menu_Rows.filter(e => e.Parent_ID == "0");
        this.Label_Menu_Rows.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));
      } catch { }

    });
  }


  get_ref(data) {

    
    try{
    return this.Reference_Rows.filter(e => e.Ref_ID == data);
    }catch{

      return [];
    }
  }
  get_child(parent) {
    var data = this.Menu_Rows.filter(e => e.Parent_ID == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  get_childs(parent) {
    var data = this.Menu_Rows.filter(e => e.Module == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  public Menu_data = "";
  public Menu_Master_Rows = [];
  public Parent_Menus = [];
  get_Menu_Master() {
    this.getc("Api/Setting/get_Menu_master").subscribe((res: any) => {
      this.Menu_Master_Rows = JSON.parse(res).record;

      this.Parent_Menus = this.Menu_Master_Rows.filter(e => e.Parent_ID == "0");



    });
  }


  loading = false;


  Num(data) {
    return Number(data).toFixed(2);
  }



  public filter_data="";
  pdfType = "Open";


  get(url) {
    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "&Company=" + this.Company, { headers: reqHeader })
  }

  getc(url) {

    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "?Company=" + this.Company, { headers: reqHeader });
  }



  d_get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(url + "&Company=" + this.Company, { headers: reqHeader })
  }


  public load_page(data) {
    this.router.navigate([data], { replaceUrl: true });
  }

  public load_page1(data) {
    this.router.navigate([data]);
  }


  public Seraial_No_Settings_Rows = [];
  public Perment_Seraial_No_Settings_Rows = [];
  public Reset_Seraial_No_Settings_Row = [];
  public Orderby_Name = "Name";
  get_Seraial_No_Settings() {

    this.loading = true;
    this.isload = true;
    this.Seraial_No_Settings_Rows = [];
    this.get("Api/Setting/get_Seraial_No_Settings?Order_by=" + this.Orderby_Name).subscribe((res: any) => {
      this.Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Perment_Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Reset_Seraial_No_Settings_Row = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }


  public Variable_Settings_Rows = [];
  public Perment_Variable_Settings_Rows = [];
  public Reset_Variable_Settings_Row = [];
  public Variable_Settings_GF = ['ID', 'S_Variable', 'S_Value', 'S_Default'];

  get_Variable_Settings() {

    this.loading = true;
    this.Variable_Settings_Rows = [];
    this.getc("Api/Setting/get_variable_Settings").subscribe((res: any) => {
      this.Variable_Settings_Rows = JSON.parse(res).record;
      this.Perment_Variable_Settings_Rows = JSON.parse(res).record;
      this.Reset_Variable_Settings_Row = JSON.parse(res).record;
      this.loading = false;

    });
  }

  public Bank_Master_Rows = [];
  get_Bank_Master() {

    this.getc("Api/Master/get_Bank_Master").subscribe((res: any) => {
      this.Bank_Master_Rows = JSON.parse(res).record;
    });
  }


  post_data(data, Table) {

    data.Company = this.Company;
    data.Created_by = this.CREATED_BY;
    data.Table_Name = Table;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.isload = true;
    this.http.post(this.Server_URL + 'api/Master/Post_Data', data, { headers: this.headers })
      .subscribe(
        (val: string) => {
          this.isload = false;
          return val;
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public tocken = "";
  get_Token() {



    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.isload = true;
    this.http.post(this.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.headers })
      .subscribe(
        (val) => {
          this.tocken = val['access_token'];

        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public length_of(dt) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return len
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
      } catch { }
    }

    return len;
  }

  load_themes(data) {
    var d = "default.css";
    try {
      d = localStorage.getItem('Theme');
    } catch { }

    if (d != "default.css" && d != null) {

      this.loadStyle(d);

    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  public sum_of(dt, field) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return dt.filteredValue.reduce((sum, current) => sum + parseFloat(current[field]), 0);
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
        return dt.value.reduce((sum, current) => sum + parseFloat(current[field]), 0);
      } catch { }
    }

    return len;
  }



  public Excel_Data: any = {
    'ID': '',
    "item": '',
    "Header": '',
    "Footer": '',
    "Left_Data": '',
    "Right_Data": '',
    "Report_Name": ''

  };
  headers;

  export_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Home/JsontToExcel?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  export_excel1() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Home/JsontToExcel1?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  upload_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Report/JsontToExcel_Upload?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  //loading = false;

  public Dashboard_Row = [];
  get_Dashboard() {
    this.Dashboard_Row = [];
    this.loading = true;

    this.getc("Api/Master/get_dashboard").subscribe((res: any) => {
      this.Dashboard_Row = JSON.parse(res).record;
      this.loading = false;
    });
  }
  File_Name = "Data";

  export_pdf() {
    this.Excel_Data.Company_Data = JSON.parse(JSON.stringify(this.Current_User));
    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {
            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=pdf";
            window.open(this.Server_URL + "PDF/Export_Pdf?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  get_fields_of(Table) {
    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.Visible == "True");
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  get_grid_fields_of(Table) {
    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table).toLowerCase()).filter(e => e.GVisible == "True");
    return this.F_Row.sort((a, b) => Number(a["GOrder"]) - Number((b["GOrder"])));

  }

  public Field_Setting = [];
  get_Field_Setting() {
    this.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {
      this.Field_Setting = JSON.parse(res).record;

    });

  }
  public Field_Setting_Table_Row = [];
  get_Field_Setting_Table() {
    this.getc("Api/Setting/get_Field_Setting_Table").subscribe((res: any) => {
      this.Field_Setting_Table_Row = JSON.parse(res).record;

    });
  }
  Lable_Order_entry="Order Entry";
  Lable_Takeaway="Takeaway";
  Lable_Dining="Dining";
  Lable_Purchase = "Purchase";
  Lable_Sales = "Sales";
  Lable_Receipt = "Receipt";
  Lable_Payment = "Payment";
  Lable_Expence = "Expence";
  Lable_Daybook = "Day Book";
  Lable_Receivable = "Receivable";
  Lable_Payable = "Payable";
  Lable_Item_Master = "Item Master";
  Lable_Ledger = "LedgerMaster";
  Lable_Reports = "Reports";
  Lable_Minimum_Stock = "Minimum Stock";

  public dashboard_data() {
    this.get_Category_Master()
    this.get_Level_Master();
    this.get_SLedger_Master();
    this.get_Bank_Master();
  }
  public DF_Bill_Type = "Tax Invoice";

  open_pdf(data) {
  }


  public DF_Paymode = "244";
  public ledger_count = 0;
  public Minimum_Stock_count=0;
  public DB_Vadi_Total = 0;
  public DB_Sales_Date_Diff_= 0;



  public Item_Order_by = " pm_item_name asc";
  public Product_Master_Rows = [];
  public Product_Master_col_GF = ['pm_brand','pm_category', 'pm_item_name','pm_gst_per','pm_mrpprice','pm_purprice','pm_uom','pm_hsn_code' ];

  get_product_Master(Status) {
      
    this.get("Api/Master/get_product_Master?Status=" + Status + "&vType=Product&Order_by="+this.Item_Order_by).subscribe((res: any) => {
      
      this.Product_Master_Rows = JSON.parse(res).record;

    });
  }



  public Category_Master_Export = [];
public Category_order_by=" cat_id asc";
public Category_Master_Rows = [];
public Category_Master_col_GF = ['cat_code','cat_name'];
 
get_Category_Master() {
this.isload = true;
  this.get("Api/Master/get_Category_Master?Status="+this.Status+"&Order_by="+this.Category_order_by).subscribe((res: any) => {
    this.isload = false;
    this.Category_Master_Rows = JSON.parse(res).record;
  });
}


public Level_Master_Export = [];
public Level_order_by=" lev_id asc";
public Level_Master_Rows = [];
public Level_Master_col_GF = ['lev_code','lev_name'];
 
get_Level_Master() {
this.isload = true;
  this.get("Api/Master/get_Level_Master?Status="+this.Status+"&Order_by="+this.Level_order_by).subscribe((res: any) => {
    this.isload = false;
    this.Level_Master_Rows = JSON.parse(res).record;
  });
}



public Questionary_Master_Export = [
  { Field: 'Code', Name: 'Code', Align: '' },
  { Field: 'Name', Name: 'Name', Align: '' },
  { Field: 'User_ID', Name: 'User_ID', Align: '' },
  { Field: 'Password', Name: 'Password', Align: '' },
  { Field: 'Rights', Name: 'Rights', Align: '' },
];
public Questionary_order_by=" qm_id asc";
public Questionary_Master_Rows = [];
public Questionary_Master_col_GF = ['qm_question','qm_answer1','qm_answer2','qm_answer3','qm_answer4','qm_answer5'];
 
get_Questionary_Master() {
this.isload = true;
  this.get("Api/Master/get_Questionary_Master?Status="+this.Status+"&Order_by="+this.Questionary_order_by).subscribe((res: any) => {
    this.isload = false;
    this.Questionary_Master_Rows = JSON.parse(res).record;
  });
}



public Document_Upload_Export = [];
public Doc_order_by=" doc_id asc";
public Document_Upload_Rows = [];
public Document_Upload_col_GF = ['doc_category','doc_testlevel','doc_filename','doc_description'];
 
get_Document_Upload() {
this.isload = true;
  this.get("Api/Master/get_Document_Upload?Status="+this.Status+"&Order_by="+this.Doc_order_by).subscribe((res: any) => {
    this.isload = false;
    this.Document_Upload_Rows = JSON.parse(res).record;
  });
}

  public Plan_Master_Export = [];
public Plan_order_by=" plan_id asc";
public Plan_Master_Rows = [];
public Plan_Master_col_GF = ['cus_code','cus_name', 'cus_contactperson','cus_area', 'cus_contactno', 'cus_gstin', 'cus_state', 'cus_created_by'];
 
get_Plan_Master() {
this.isload = true;
  this.get("Api/Master/get_Plan_Master?Status="+this.Status+"&Order_by="+this.Plan_order_by).subscribe((res: any) => {
    this.isload = false;
    this.Plan_Master_Rows = JSON.parse(res).record;
  });
}


  public Customer_Master_Export = [];
public Cus_Type="Customer";
public Status="A";
public Route="ALL";
public cus_order_by=" cast(cus_code as int) asc";
public Customer_Order_by = " cus_name asc";
public Ledger_Rows1 = [];
public Ledger_Master_Rows = [];
public Customer_Master_col_GF = ['cus_code','cus_name', 'cus_contactperson','cus_area', 'cus_contactno', 'cus_gstin', 'cus_state', 'cus_created_by'];
 
get_SLedger_Master() {
this.isload = true;
  this.get("Api/Master/get_Ledger_Master?Status="+this.Status+"&Type="+this.Cus_Type+"&Route="+this.Route+"&Order_by="+this.cus_order_by).subscribe((res: any) => {
    this.isload = false;
    this.Ledger_Master_Rows = JSON.parse(res).record;
    this.Ledger_Rows1 = JSON.parse(res).record;
  });
}


get_Ledger_group(data)
{
    return  this.Ledger_Master_Rows.filter(e=>e.Group_ID==data);
}
public Biller_Rows = [];


public Item_Row_Cout = 0;
public Item_Master_Rows = [];
public Item_Orderby_Name = "Category,Item_Name";
public Perment_Item_Master_Row = [];
public Reset_Item_Master_Row = [];
public Item_Master_RM_Rows=[];
public item_Category_ = "";
public Item_Category = [];


public RM_all_item:boolean=true;
get_Item_Master() {

  this.loading = true;
  this.isload = true;
  this.Item_Master_Rows = [];
  this.get("Api/Master/get_Item_Master?Order_by=" + this.Item_Orderby_Name).subscribe((res: any) => {
    this.Item_Master_Rows = JSON.parse(res).record;

    this.Perment_Item_Master_Row = JSON.parse(res).record;
    this.Reset_Item_Master_Row = JSON.parse(res).record;
  

    if(this.RM_all_item==true)
    {
      this.Item_Master_RM_Rows=this.Perment_Item_Master_Row;
    }else
    {
      this.Item_Master_RM_Rows=this.Perment_Item_Master_Row.filter(e=>e.Item_Group=="2");
    }
    
  

    this.loading = false;
    this.isload = false;


    try {
      this.Item_Row_Cout = this.Item_Master_Rows.length;

    } catch { }

      this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

    // console.log(this.Item_Category);
  });
}

public Customer_Search: boolean = true;


getFile(url: string): Promise<Blob> {
  return this.http.get(url, { responseType: 'blob' }).toPromise();
}

convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

public fileUploadFlag =false;

public selectedFiles =[];



getFileData(url: string, id: number): Observable<any> {
  
  return this.http.get(url, { responseType: 'blob' }).pipe(
    map(blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = (reader.result as string).split(',')[1]; // Extract base64 part
          resolve({
            id: id,
            name: url.split('/').pop(), // Extract file name from URL
            base64: base64String,
            type: blob.type
          });
        };
        reader.onerror = () => reject(new Error('FileReader failed'));
        reader.readAsDataURL(blob);
      });
    }),
    // Convert the Promise to an Observable
    map(promise => promise as any)
  );
}


public Ledger_Pending_Export = [

  { Field: 'label', Name: 'Bill No', Align: '' },
  { Field: 'Bill_Date', Name: 'Date', Align: '' },
  { Field: 'days', Name: 'Days', Align: '' },
  { Field: 'Net_Amt', Name: 'Bill Amount', Align: 'right' },
  { Field: 'Bill_Amount', Name: 'Due Amount', Align: 'right' }

];


public Opening_Entry_Rows;
public Item_Master_GF = ['Category','Item_Name','HSN_Code','GST_Per'];

public Pay_Mode = "";
public Bill_Mode = "Cash";
public Receiving_Bank = "0";
public Cheque_No = "";
public Cheque_Date = this.Today_Date;
public Card_Charge = "";
public Remarks = "";
public pay_Mode_reset() {
  this.Pay_Mode = this.Cash_ID;
  this.Receiving_Bank = "0";
  this.Cheque_No = "";
  this.Cheque_Date = this.Today_Date;
  this.Card_Charge = "";
  this.Remarks = "";

}


public Income_Export = [

  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_category', Name: 'Category', Align: '' },
  { Field: 'oc_ledger_name', Name: 'Ledger', Align: '' },
  { Field: 'oc_narration1', Name: 'Description', Align: '' },

  { Field: 'oc_paymode', Name: 'Pay Mode', Align: '' },
  { Field: 'oc_created_by', Name: 'User', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }

];
public Expense_Export = [

  { Field: 'oc_date', Name: 'Date', Align: '' },
  { Field: 'oc_category', Name: 'Category', Align: '' },
  { Field: 'oc_ledger_name', Name: 'Ledger', Align: '' },
  { Field: 'oc_narration1', Name: 'Description', Align: '' },

  { Field: 'oc_paymode', Name: 'Pay Mode', Align: '' },
  { Field: 'oc_created_by', Name: 'User', Align: '' },
  { Field: 'oc_amount', Name: 'Amount', Align: 'right' }

];
public S_Exp_Category = "0";

public oc_type="";
public Expense_Details_GF = ['Category_', 'Narration1', 'Amount'];
public Exp_Rows = [];
public Exp_Total = 0;
get_expense() {
  this.isload = true;
  this.Exp_Rows = [];
  this.get("Api/Transaction/get_OtherCollection_details?From=" + this.S_From + "&To=" + this.S_To + "&Type=" + this.oc_type ).subscribe((res: any) => {
    this.Exp_Rows = JSON.parse(res).record;
    this.isload = false;
    try {
      this.Exp_Total = 0;
      this.Exp_Total = (this.Exp_Rows.reduce((sum, current) => sum + parseFloat(current.oc_amount), 0)).toFixed(2);
    } catch
    { }
  });

}



public Contra_Export = [

  { Field: 'Ref_No', Name: 'No', Align: '' },
  { Field: 'Ref_Date_', Name: 'Date', Align: '' },
  { Field: 'From_Account_', Name: 'From', Align: '' },
  { Field: 'To_Account_', Name: 'To', Align: '' },

  { Field: 'Naration', Name: 'Description', Align: '' },
  { Field: 'Amount', Name: 'Amount', Align: 'right' }

];

public Contra_Details_GF = [ 'Naration', 'Amount','From_Account','To_Account'];
public Contra_Rows = [];
public Contra_Total = 0;
get_Contra() {
  this.isload = true;
  this.Contra_Rows = [];
  this.get("Api/Transaction/get_Contra?From=" + this.S_From + "&To=" + this.S_To ).subscribe((res: any) => {
    this.Contra_Rows = JSON.parse(res).record;
    this.isload = false;
    try {
      this.Contra_Total = 0;
      this.Contra_Total = (this.Contra_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
    } catch
    { }
  });

}

public ledger_status=[];
public  Ledger_St="";
public  Ledger_Bal="0";
get_Ledger_Amount(data)
{
this.get("Api/Reports/get_customer_balance?Ledger=" +data).subscribe((res: any) => {
 this.ledger_status= JSON.parse(res).record;
 this.Ledger_Bal=this.ledger_status[0].Balance;
 this.Ledger_St=this.ledger_status[0].Status;
});
}



}
